import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Radio } from "@nulogy/components";
import { config } from "../../playgrounds/radio";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Radio`}</h1>
    <p>{`Radio buttons allow one selection from a group of options`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Guidelines`}</h2>
    <ul>
      <li parentName="ul">{`Whenever possible use radio buttons for short lists (~ 5-7)`}</li>
      <li parentName="ul">{`Add labels, errors and default selections with `}<a parentName="li" {...{
          "href": "/radio-group"
        }}>{`Radio Group`}</a></li>
      <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
          "href": "/select"
        }}>{`Select`}</a>{` for long lists`}</li>
    </ul>
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/select"
        }}>{`Select`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`RadioGroup`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      